import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../components/table/table';

const TopValidatorsTable = ({ validators, validatorsError }) => {
  if (validatorsError) {
    return (
      <div>Error retrieving validators. Details: {validatorsError.message}</div>
    );
  }
  return (
    <Table
      columns={['Address', 'Balance', 'Voting Power']}
      rows={validators}
    />
  );
};

TopValidatorsTable.displayName = 'TopValidatorsTable';

TopValidatorsTable.propTypes = {
  validators: PropTypes.array,
  validatorsError: PropTypes.any,
};

export default TopValidatorsTable;
