import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import BlochchainSwitch from './blockchainSwitch';

export const mapStateToProps = state => ({
  query: state.search.query,
  filter: state.search.filter,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlochchainSwitch);
