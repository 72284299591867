import * as types from './actionTypes';
import { INFO_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';
import api from '../../config/api';

const getTotalDelegatorsSuccess = response => ({
  type: types.GET_TOP_DELEGATORS_SUCCESS,
  response,
});

const getTotalDelegatorsError = error => ({
  type: types.GET_TOP_DELEGATORS_ERROR,
  error,
});

export const getTotalDelegators = () => async dispatch => {
  try {
    const response = await api(INFO_API_TYPE, CHRONOS_NETWORK).infoDelegatorGet();

    if (response) {
      dispatch(getTotalDelegatorsSuccess(response));
    }
  } catch (e) {
    console.log('e', e);
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    dispatch(getTotalDelegatorsError(error));
  }
};
