import React from 'react';
import PropTypes from 'prop-types';
import styles from '../account.module.scss';
import Tab from '../../../components/tab/tab';
import PaginationTable from '../../../components/table/paginationTable';

import Loader from '../../../components/loader/loader';
import { mapTxType } from '../../../utils/formatters';

const getGasPrice = ({ fee }) => {
  const gasPriceString = fee.amount || '';
  const gasPrice = +gasPriceString.split(' ').shift();
  return gasPrice;
};

const getUsedGas = ({ fee }) => fee.gas || 0;

const getFee = (usedGas, gasPrice) => `${usedGas * gasPrice} OLT`;

const transactionListMapper = (txn = {}) => {
  const translatedTxType = mapTxType(txn);

  const gasPrice = getGasPrice(txn);
  const usedGas = getUsedGas(txn);

  return [
    txn.hash || '',
    translatedTxType,
    txn.recipients[0].amount || '',
    txn.from || '',
    txn.recipients[0].account || '',
    txn.blockHeight ? txn.blockHeight : '',
    getFee(usedGas, gasPrice),
    usedGas,
    txn.fee.amount || '',
  ];
};

// Move to helper
const getTransactionChainsMap = (txs = []) => {
  const result = {};

  txs.forEach(txn => {
    const { hash: txHashValue } = txn;

    result[`tx_${txHashValue}`] = {
      txHash: txn.hash || '',
      chainID: txn.chainID || '',
    };
  });

  return result;
};

const Transactions = ({
  isLoading,
  transactions,
  activeTab,
  updateActiveTab,
  totalPage,
  updateActivePage,
  page, pagesize, currency,
}) => {
  const transactionsArr = transactions.map(transactionListMapper);
  const transactionsChainMap = getTransactionChainsMap(transactions);

  return (
    <div className={`container ${styles.table}`}>
      {isLoading ? (
        <Loader message="Loading block data..." />
      ) : (
        <div>
          <div className={styles.tabs}>
            <Tab
              activeTab={activeTab}
              onTabClick={tab => updateActiveTab(tab)}
              tabs={['Transactions']}
              autoAlign
            />
          </div>
          <PaginationTable
            pageLimit={pagesize}
            page={page}
            columns={['Tx Hash', 'Type', 'Value', 'From', 'To', 'Height', 'Fee', 'Used Gas', 'Gas Price']}
            currency={currency}
            rows={transactionsArr}
            updateActivePage={updateActivePage}
            totalPage={totalPage}
            chains={transactionsChainMap}
            txHashColumnIndex={0}
          />
        </div>
      )}
    </div>
  );
};

Transactions.displayName = 'AccountTransactions';
Transactions.propTypes = {
  isLoading: PropTypes.bool,
  transactions: PropTypes.array,
  activeTab: PropTypes.string,
  currency: PropTypes.string,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  pagesize: PropTypes.number,
  updateActiveTab: PropTypes.func,
  updateActivePage: PropTypes.func,
};

export default Transactions;
