import { connect } from 'react-redux';
import DomainInfo from './domainInfo';

export const mapStateToProps = state => ({
  info: state.domain.info,
});

const mapDispatchToProps = { };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DomainInfo);
