import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { connectRouter } from 'connected-react-router';

// Import Reducers
import account from '../views/account/reducer';
import swap from '../views/swap/reducer';
import search from '../state/searchBar/reducer';
import transaction from '../views/transaction/reducer';
import domain from '../views/domain/reducer';
import block from '../views/block/reducer';
import defaultSearch from '../views/search/reducer';
import kpiMetrics from '../state/kpiCards/reducer';
import topDelegators from '../views/topDelegators/reducer';
import topValidators from '../views/topValidators/reducer';

// Export Reducers wirh history
const createReducer = history => combineReducers({
  router: connectRouter(history),
  browser: createResponsiveStateReducer({
    mobile: 768,
    tablet: 1024,
    desktop: 1130,
  }),
  account,
  swap,
  search,
  transaction,
  block,
  domain,
  defaultSearch,
  kpiMetrics,
  topDelegators,
  topValidators
});

export default createReducer;
