const DEVNET = 'devnet';
const CHRONOS = 'chronos';
// const KAINOS = 'kainos';
const KRATOS = 'kratos';

const DEVNET_URL = process.env.DEVNET_URL || 'http://devnet2.oneledger.network';
const CHRONOS_URL = process.env.CHRONOS_URL || 'https://chronos.oneledger.network';
// const KAINOS_URL = process.env.KAINOS_URL || 'https://kainos.oneledger.network';
const KRATOS_URL = process.env.KRATOS_URL || 'https://kratos.oneledger.network';

export const NETWORKS = {
  DEVNET,
  CHRONOS,
  KRATOS,
  DEVNET_URL,
  CHRONOS_URL,
  KRATOS_URL,
};

export const RPC_URLS = {
  [KRATOS]: 'https://fullnode-sdk.kratos.oneledger.network/jsonrpc',
  [CHRONOS]: 'https://fullnode-sdk.chronos.oneledger.network/jsonrpc',
  [DEVNET]: 'http://fullnode-sdk.devnet2.oneledger.network/jsonrpc',
};

export const defaultNetworkName = process.env.NETWORK_NAME || KRATOS;


export const OLT_REMOTE_URL = RPC_URLS[defaultNetworkName] || RPC_URLS[KRATOS];

export const searchFilters = [
  {
    label: 'Default',
    value: 'search',
  },
  {
    label: 'Account',
    value: 'account',
  },
  {
    label: 'Transaction',
    value: 'transaction',
  },
  {
    label: 'Block',
    value: 'block',
  },
  {
    label: 'Domain',
    value: 'domain',
  },
];

export const ROUTES = {
  HOME: '/',
  BLOCK: '/block',
  ACCOUNT: '/account',
  TRANSACTION: '/transaction',
  SWAP: '/swap',
  DOMAIN: '/domain',
  TOP_DELEGATORS: '/topDelegators',
  TOP_VALIDATORS: '/topValidators',
};


export const networkFilters = [
  {
    label: 'Mainnet',
    value: 'mainnet',
    url: 'https://mainnet-explorer.oneledger.network',
  },
  {
    label: 'Testnet',
    value: 'frankenstein',
    url: 'https://frankenstein-explorer.oneledger.network',
  },
];

const TOP_DELEGATORS = 'home';
export const defaultBlockchain = TOP_DELEGATORS;

export const blockchainProducts = [
  {
    label: 'Blockchain',
    value: 'home',
    url: ROUTES.HOME,
  },
  {
    label: 'Top Delegators',
    value: 'topDelegators',
    url: ROUTES.TOP_DELEGATORS,
  },
  {
    label: 'Top Validators',
    value: 'topValidators',
    url: ROUTES.TOP_VALIDATORS,
  },
];

export const VIEW_TYPE = {
  BLOCK: 'BLOCK',
  SWAP: 'SWAP',
  TRANSACTION: 'TRANSACTION',
  ACCOUNT: 'ACCOUNT',
  DOMAIN: 'DOMAIN',
};

export const chartFilters = [
  {
    label: '1 week',
    value: 7,
  },
  {
    label: '2 weeks',
    value: 14,
  },
];

export const SWAP_STEPS = ['Swap Listing', 'Swap Match', 'Contract Created', 'Contract Confirmed'];

export const STREAM_LIMIT = 15;
export const STREAM_CHUNK_LIMIT = 10;
export const BLOCK_STREAM_UPDATE_TIMER = 2000;
export const BLOCK_STREAM_CHUNK_UPDATE_TIMER = 4000;
export const SWAP_STREAM_UPDATE_TIMER = 25000;
export const TRANSACTION_STREAM_UPDATE_TIMER = 2500;
export const KPI_METRICS_UPDATE_TIMER = 60000;

// Map of decimals for the currencies
export const CURRENCY_OLT = 'OLT';

export const COIN_DECIMAL = {
  OLT: 18,
};
