import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TopDelegators from './topDelegators';
import { getTotalDelegators } from './actions';

export const mapStateToProps = state => ({
  topDelegatorsInfo: state.topDelegators.data,
  isLoading: state.topDelegators.isLoading,
  topDelegatorsError: state.topDelegators.error,
});


const mapDispatchToProps = {
  getTotalDelegators,
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopDelegators),
);
