import * as types from './actionTypes';

export const defaultState = {
  isLoading: false,
  data: [],
  error: null,
};

const topDelegators = (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_TOP_DELEGATORS_START:
      return {
        ...state,
        ...{
          isLoading: true,
        },
      };
    case types.GET_TOP_DELEGATORS_SUCCESS:
      return {
        ...state,
        ...{
          data: action.response,
          isLoading: false,
        },
      };
    case types.GET_TOP_DELEGATORS_ERROR:
      return {
        ...state,
        ...{
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default topDelegators;
