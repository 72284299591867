import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './topDelegators.module.scss';
import Loader from '../../components/loader/loader';
import TopDelegatorsTable from './components/topDelegatorsTable';
import Tab from '../../components/tab/tab';
import OnsSdkApi from '../../services/onsApi';

const listMapper = (delegator = {}) => {
  const { address, delegationAmount, delegationReward } = delegator;

  const {
    activeAmount: activeDelegationAmount,
    pendingAmount: pendingDelegationAmount } = delegationAmount;

  const {
    activeAmount: activeDelegationReward,
    pendingAmount: pendingDelegationReward } = delegationReward;

  return [
    address || '',
    OnsSdkApi.convertPriceToGui(activeDelegationAmount) || '',
    OnsSdkApi.convertPriceToGui(pendingDelegationAmount) || '',
    OnsSdkApi.convertPriceToGui(activeDelegationReward) || '',
    OnsSdkApi.convertPriceToGui(pendingDelegationReward) || '',
  ];
};


const TopDelegators = (props) => {
  const { getTotalDelegators, topDelegatorsInfo, isLoading, topDelegatorsError } = props;

  const topDelegatorsArr = topDelegatorsInfo.map(listMapper);

  const sectionContainer = `section ${styles.topDelegators}`;
  const tableContainer = `container ${styles.tableContainer}`;

  const loadDataHandler = useCallback(() => {
    getTotalDelegators();
  }, [getTotalDelegators]);

  useEffect(() => {
    loadDataHandler();
  }, [loadDataHandler]);

  return (
    <section className={sectionContainer}>
      <div className={tableContainer}>
        { isLoading ? (
          <div className={styles.loader}>
            <Loader message="Delegators data loading..." />
          </div>
        ) : (
          <div>
            <div className={styles.tabs}>
              <Tab
                activeTab="Top Delegators List"
                onTabClick={() => {}}
                tabs={['Top Delegators List']}
                autoAlign
              />
            </div>
            <TopDelegatorsTable
              delegators={topDelegatorsArr}
              delegatorsError={topDelegatorsError}
            />
          </div>

        ) }
      </div>
    </section>
  );
};

TopDelegators.propTypes = {
  getTotalDelegators: PropTypes.func,
  topDelegatorsInfo: PropTypes.array,
  isLoading: PropTypes.bool,
  topDelegatorsError: PropTypes.any,
};

TopDelegators.displayName = 'TopDelegators';

export default TopDelegators;
