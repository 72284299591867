import moment from 'moment';
import { dateFormatter, ageFormatter } from '../../utils/formatters';

export const rows = () => {
  const object = [
    '0xc21022c4kf83kc21022c4kf83kc88fk393kc93j9dfb93c88fk393kc93j9dfb93',
    250,
    dateFormatter(moment().subtract(6, 'days')),
    ageFormatter(moment().subtract(6, 'days')),
  ];

  const arr = [];

  for (let i = 0; i < 100; i++) {
    arr.push(object);
  }
  return arr;
};

export const buildTransactionArray = txn => {
  // const columns = ['hash', 'fee', 'height'];
  // 'Height', 'Fee', 'Gas Price', 'Gas Used', 'Hash'
  const txnArr = [txn.blockHeight, txn.fee.totalFee, txn.fee.amount, txn.fee.gas, txn.hash];

  // columns.map(key => {
  //   if (key === 'timestamp') {
  //     if (txn[key] === undefined) {
  //       txnArr.push('-');
  //     } else {
  //       txnArr.push(dateFormatter(txn[key]));
  //     }
  //   } else {
  //     txnArr.push(txn[key]);
  //   }
  //   return key;
  // });
  return txnArr;
};

export const buildTransactionsArray = transactions =>
  transactions.map(buildTransactionArray);
