import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from './routes';

// Import Routes
import home from '../views/home/home.routes';
import topDelegators from '../views/topDelegators/topDelegators.routes';
import topValidators from '../views/topValidators/topValidators.routes';
import swap from '../views/swap/swap.routes';
import account from '../views/account/account.routes';
import domain from '../views/domain/domain.routes';
import transaction from '../views/transaction/transaction.routes';
import block from '../views/block/block.routes';
import search from '../views/search/search.routes';

export const mapStateToProps = () => ({
  routes: [
    ...home, ...swap, ...account, ...domain, ...transaction,
    ...block, ...search, ...topDelegators, ...topValidators],
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Routes),
);
