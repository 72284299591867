import _ from 'underscore';
import * as types from './actionTypes';
import api from '../../config/api';
import {
  BLOCKS_API_TYPE,
  TRANSACTIONS_API_TYPE,
  CHRONOS_NETWORK,
  ACCOUNTS_API_TYPE,
  DOMAINS_API_TYPE,
} from '../../config/constants';
import { ROUTES } from '../../constants/general';

const getDefaultSearchStart = () => ({
  type: types.GET_DEFAULT_SEARCH_START,
});

const getDefaultSearchSuccess = response => ({
  type: types.GET_DEFAULT_SEARCH_SUCCESS,
  response,
});

const getDefaultSearchError = error => ({
  type: types.GET_DEFAULT_SEARCH_ERROR,
  error,
});

export const getDefaultSearch = ({ query }) => async dispatch => {
  dispatch(getDefaultSearchStart());

  try {
    const routes = [ROUTES.BLOCK, ROUTES.TRANSACTION, ROUTES.ACCOUNT, ROUTES.DOMAIN];

    const responseArr = await Promise.all([
      api(BLOCKS_API_TYPE, CHRONOS_NETWORK)
        .blocksBlockHeightGet(query)
        .catch(e => ({ error: e.message })),
      api(TRANSACTIONS_API_TYPE, CHRONOS_NETWORK)
        .txsTxIDGet(query)
        .catch(e => ({ error: e.message })),
      api(ACCOUNTS_API_TYPE, CHRONOS_NETWORK)
        .accountsAddressGet(query)
        .catch(e => ({ error: e.message })),
      api(DOMAINS_API_TYPE, CHRONOS_NETWORK)
        .txsDomainGet(query)
        .catch(e => ({ error: `error while trying to get txs for domain - ${e.message}` })),

    ]);

    const responseType = responseArr.map((response, index) => {
      if (!('error' in response)) {
        const route = routes[index];

        if (route === ROUTES.TRANSACTION) {
          if (response.type === 'payment') {
            response.amount = { currency: response.recipients[0].amount.currency };
            let txnvalue = 0;
            for (let recIndex = 0; recIndex < response.recipients.length; recIndex++) {
              const recipient = response.recipients[recIndex];
              const fvalue = recipient.amount.value.split(' ')[0];
              txnvalue += parseFloat(fvalue);
            }
            response.amount.value = txnvalue.toString();
          }
        }

        if (route === ROUTES.DOMAIN) {
          const { totalPage, txs } = response;

          // We are imitating the missing 404 response for a non-found domain,
          // because we still get 200 with empty txs[]
          if (totalPage === 0) {
            return null;
          }

          response.domainName = query;
          response.transactionsCount = txs.length;
        }

        return {
          route: `${route}/${query}`,
          type: route.replace('/', '').toUpperCase(),
          response,
          query,
        };
      }

      return null;
    });

    const uniqueResponse = _.without(responseType, null);

    return dispatch(getDefaultSearchSuccess(uniqueResponse));
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    return dispatch(getDefaultSearchError(error));
  }
};
