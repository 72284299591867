import { request, requestConfig, util } from '@oneledger/ons-sdk';
import { ONS_RPC_ENDPOINT, STORE_CONFIG, errCodeDomainNotFound } from '../config/constants';

const ENV_SDK = { url: ONS_RPC_ENDPOINT, storeConfig: { ...STORE_CONFIG } };

const convertPriceToGui = price => `${util.responsePriceConverter(price, requestConfig.OLT.decimal)} ${requestConfig.OLT.currency}`;

const getSubDomains = async (payload) => {
  const { domainName } = payload;

  const byParentObj = {
    queryDomainType: requestConfig.QueryDomainType.SubDomainByName,
    domainName,
  };

  let response = [];

  try {
    const queryResult = await request.queryDomains(byParentObj, ENV_SDK);
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    const { code } = err;

    if (code === errCodeDomainNotFound) {
      throw new Error(errCodeDomainNotFound);
    }

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not fetch subdomains'}",
        Details "${err.detail || 'No details are given'}"
      `,
    );
  }

  return response;
};


const getDomainInfo = async ({ domainName }) => {
  const byNameObj = {
    queryDomainType: requestConfig.QueryDomainType.ByName,
    domainName: `${domainName.trim().toLowerCase()}`,
  };

  let response;

  try {
    const queryResult = await request.queryDomains(byNameObj, ENV_SDK);
    ({ response } = queryResult);
  } catch (queryError) {
    const err = queryError.error;

    const { code } = err;

    if (code === errCodeDomainNotFound) {
      throw new Error(errCodeDomainNotFound);
    }

    throw new Error(
      `
        Code "${err.code || 'Uknown Error'}",
        Message "${err.message || 'Could not fetch subdomains'}",
        Details "${err.detail || 'No details are given'}"
      `,
    );
  }

  return response;
};

export default {
  getSubDomains,
  getDomainInfo,
  convertPriceToGui,
};
