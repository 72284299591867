import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore.string';
import styles from './multipleResults.module.scss';
import { getResultCard } from '../resultCard/helper';

// todo - move to styles
const mainWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
};

const MultipleResults = ({ data, push }) => (
  <section className={`${styles.container}`}>
    <div className="container">

      <p className={`${styles.title} is-size-6-touch has-text-centered`}>
        Search results for:<em className={`${styles.query} is-size-6-touch`}>{` "${data[0].query}"`}</em>
      </p>

      <div className={styles.resultWrapper}>

        <div className={`${styles.results}`} style={mainWrapper}>
          {data.map(item => {
            const { type, route, response } = item;

            const ResultCard = getResultCard(type);

            return (
              <div
                className={styles.resultItemWrapper}
                key={type}
                onClick={() => {
                  push(route);
                }}
              >
                <ResultCard item={response} type={_.capitalize(type.toLowerCase())} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </section>
);

MultipleResults.displayName = 'MultipleResults';
MultipleResults.propTypes = {
  push: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      type: PropTypes.string,
      response: PropTypes.object,
      query: PropTypes.string,
    }),
  ),
};

export default MultipleResults;
