export const UPDATE_ACTIVE_TAB = 'DOMAINS/UPDATE_ACTIVE_TAB';
export const GET_DOMAIN_INFO_START = 'DOMAINS/GET_DOMAIN_INFO_START';
export const GET_DOMAIN_INFO_SUCCESS = 'DOMAINS/GET_DOMAIN_INFO_SUCCESS';
export const GET_DOMAIN_INFO_ERROR = 'DOMAIN/GET_DOMAIN_INFO_ERROR';
export const GET_DOMAIN_TRANSACTIONS_START = 'DOMAINS/GET_DOMAIN_TRANSACTIONS_START';
export const GET_DOMAIN_TRANSACTIONS_SUCCESS = 'DOMAINS/GET_DOMAIN_TRANSACTIONS_SUCCESS';
export const GET_DOMAIN_TRANSACTIONS_ERROR = 'DOMAIN/GET_DOMAIN_TRANSACTIONS_ERROR';
export const GET_DOMAIN_SUBDOMAINS_START = 'DOMAINS/GET_DOMAIN_SUBDOMAINS_START';
export const GET_DOMAIN_SUBDOMAINS_SUCCESS = 'DOMAINS/GET_DOMAIN_SUBDOMAINS_SUCCESS';
export const GET_DOMAIN_SUBDOMAINS_ERROR = 'DOMAIN/GET_DOMAIN_SUBDOMAINS_ERROR';
