import { connect } from 'react-redux';
import { getAccount } from './actions';
import Account from './account';

export const mapStateToProps = state => ({
  pagesize: state.account.transactions.pagesize,
  page: state.account.transactions.page,
  currency: state.account.transactions.currency,
});

const mapDispatchToProps = {
  getAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
