import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../components/table/table';

const TopDelegatorsTable = ({ delegators, delegatorsError }) => {
  if (delegatorsError) {
    return (
      <div>Error retrieving delegators. Details: {delegatorsError.message}</div>
    );
  }
  return (
    <Table
      columns={['Address', 'Active Amount', 'Pending Amount', 'Active Reward', 'Pending Reward']}
      rows={delegators}
    />
  );
};

TopDelegatorsTable.displayName = 'TopDelegatorsTable';

TopDelegatorsTable.propTypes = {
  delegators: PropTypes.array,
  delegatorsError: PropTypes.any,
};

export default TopDelegatorsTable;
