import {
  AccountsApi,
  TransactionsApi,
  BlocksApi,
  Configuration,
  BlockchainApi,
} from '../api/index';

import OnsSdkApi from '../services/onsApi';

import config from './config';

import {
  ACCOUNTS_API_TYPE,
  TRANSACTIONS_API_TYPE,
  SWAPS_API_TYPE,
  BLOCKS_API_TYPE,
  KPI_API_TYPE,
  DOMAINS_API_TYPE,
  ONS_API_TYPE,
  INFO_API_TYPE,
} from './constants';

const env = process.env.NODE_ENV || 'development';
const apiConfig = network => new Configuration({ basePath: config[env].networks[network] });

const api = (type, network) => {
  const configuration = apiConfig(network);
  switch (type) {
    case ACCOUNTS_API_TYPE:
      return AccountsApi(configuration);
    case BLOCKS_API_TYPE:
      return BlocksApi(configuration);
    case TRANSACTIONS_API_TYPE:
    case SWAPS_API_TYPE:
      return TransactionsApi(configuration);
    case KPI_API_TYPE:
      return BlockchainApi(configuration);
    case INFO_API_TYPE:
      return AccountsApi(configuration);
    case DOMAINS_API_TYPE:
      return TransactionsApi(configuration);
    case ONS_API_TYPE:
      return OnsSdkApi;
    default:
      throw new Error('We do not yet support this. Please check back later.');
  }
};

export default api;
