import Domain from './domain.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: ROUTES.DOMAIN,
    path: `${ROUTES.DOMAIN}/:domainName`,
    isSecure: false,
    component: Domain,
  },
];
