const txTypesOlt = {
  // olt
  DEFAULT_MESSAGE: 'DEFAULT_MESSAGE',
  tx_4000: 'send_recipient_olt', // 'Receive OLT',
  tx_4001: 'send_sender_olt', // 'Send OLT',
  tx_4002: 'send_recipient_oeth', // 'Receive ETH',
  tx_4003: 'send_sender_oeth', // 'Send ETH',

  tx_0000: 'unknown', // 'Unknown',
  tx_4010: 'domain_send_recipient_olt', // 'Receive OLT to Domain',
  tx_4011: 'domain_send_sender_olt', // 'Send OLT to Domain',
  tx_4012: 'domain_send_recipient_oeth', // 'Receive ETH to Domain',
  tx_4013: 'domain_send_sender_oeth', // 'Send ETH to Domain',

  tx_4020: 'domain_create', // 'Create Domain',
  tx_4021: 'domain_update', // 'Update Domain',
  tx_4022: 'domain_on_sale', // 'Domain On Sale',
  tx_4023: 'domain_off_sale', // 'Domain Off Sale',
  tx_4024: 'domain_purchase_buyer', // 'Purchase Domain',
  tx_4025: 'domain_purchase_seller', // 'Sell Domain',
  tx_4026: 'domain_purchase_self', // 'Sell Domain',
  tx_4027: 'domain_delete_sub', // 'Delete Subdomain',
  tx_4028: 'domain_renew', // 'Renew Domain',

  tx_4030: 'stake', // 'stake',
  tx_4031: 'unstake', // 'unstake', // new
  tx_4040: 'withdraw', // 'Withdraw',
};

const txTypesBtc = {
  // btc
  tx_4050: 'btc_lock', // 'BTC_LOCK',
  tx_4051: 'btc_add_signature', // 'BTC_ADD_SIGNATURE',
  tx_4052: 'btc_broadcast_success', // 'BTC_BROADCAST_SUCCESS',
  tx_4053: 'btc_report_finality_mint', // 'BTC_REPORT_FINALITY_MINT',
  tx_4054: 'btc_ext_mint', // 'BTC_EXT_MINT',
  tx_4055: 'btc_redeem', // 'BTC_REDEEM',
  tx_4056: 'btc_failed_broadcast_reset', // 'BTC_FAILED_BROADCAST_RESET',
};

const txTypesEth = {
  // eth
  tx_eth_receive_from_domain: 'tx_eth_receive_from_domain', // 'Receive from Domain',
  tx_eth_send_to_domain: 'tx_eth_send_to_domain', // 'Send to Domain',
  tx_eth_receive_from_address: 'tx_eth_receive_from_address', // 'Receive from Address',
  tx_eth_send_to_address: 'tx_eth_send_to_address', // 'Send to Address',
  tx_eth_lock: 'tx_eth_lock', // 'Transfer', // make negative amount
  tx_eth_redeem: 'tx_eth_redeem', // 'Transfer', // positive amount

  tx_4060: 'eth_lock', // 'Transfer', //  ETH_LOCK - positive
  tx_4061: 'eth_report_finality_mint', // 'ETH Report Finality Mint', //  ETH_REPORT_FINALITY_MINT
  tx_4062: 'eth_redeem', // 'Transfer', // ETH_REDEEM - make negative amount
  tx_4063: 'erc20_lock', // 'ERC20_LOCK',
  tx_4064: 'erc20_redeem', // 'ERC20_REDEEM',
};

const txProposals = {
  tx_4070: 'proposal_create', // PROPOSAL_CREATE
  tx_4071: 'proposal_cancel', // PROPOSAL_CANCEL
  tx_4072: 'proposal_fund', // PROPOSAL_FUND
  tx_4073: 'proposal_vote', // PROPOSAL_VOTE
  tx_4074: 'proposal_finalize', // PROPOSAL_FINALIZE
  tx_4075: 'expire_votes', // EXPIRE_VOTES
  tx_4076: 'proposal_withdraw_funds', // PROPOSAL_WITHDRAW_FUNDS
};

const txDelegation = {
  tx_4080: 'rewards_withdraw', // REWARDS_WITHDRAW:
  tx_4090: 'add_network_delegation', // ADD_NETWORK_DELEGATION:
  tx_4091: 'network_undelegate', // NETWORK_UNDELEGATE:
  tx_4092: 'rewards_withdraw_network_delegate', // REWARDS_WITHDRAW_NETWORK_DELEGATE:
  tx_4093: 'rewards_reinvest_network_delegate', // REWARDS_REINVEST_NETWORK_DELEGATE:
};

const txExplorer = {
  tx_5000: 'domain_purchase', // DOMAIN_PURCHASE
  tx_5001: 'domain_sell', // DOMAIN_SELL
  tx_5002: 'domain_send', // DOMAIN_SEND
};

const txTypesAll = {
  ...txTypesOlt,
  ...txTypesBtc,
  ...txTypesEth,
  ...txProposals,
  ...txExplorer,
  ...txDelegation,
};

const txTypesOltList = [...Object.keys(txTypesOlt), Object.keys(txProposals)];
const txTypesEthList = Object.keys(txTypesEth);
const txTypesBtcList = Object.keys(txTypesBtc);
const txProposalsList = Object.keys(txProposals);
const txDelegationList = Object.keys(txDelegation);
const txTypesAllList = Object.keys(txTypesAll);

export default {
  txTypesAll,
  txTypesOlt,
  txTypesBtc,
  txTypesEth,
  txProposals,
  txTypesAllList,
  txTypesOltList,
  txTypesBtcList,
  txTypesEthList,
  txProposalsList,
  txDelegationList,
};
