import * as types from './actionTypes';

export const defaultState = {
  info: {
    isLoading: false,
    domainData: {},
    error: null,
  },
  transactions: {
    isLoading: false,
    transactions: [
    ],
    error: null,
    activeTab: 'Transactions',
  },
  subdomains: {
    isLoading: false,
    subdomains: [],
    error: null,
    activeTab: 'Subdomains',
  },
};

const domain = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        ...{
          transactions: {
            ...state.transactions,
            activeTab: action.tab,
          },
          subdomains: {
            ...state.subdomains,
            activeTab: action.tab,
          },
        },
      };
    case types.GET_DOMAIN_INFO_START:
      return {
        ...state,
        ...{
          info: {
            ...state.info,
            isLoading: true,
          },
        },
      };
    case types.GET_DOMAIN_INFO_SUCCESS:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            domainData: action.response,
          },
        },
      };
    case types.GET_DOMAIN_INFO_ERROR:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            error: action.error,
          },
        },
      };
    case types.GET_DOMAIN_TRANSACTIONS_START:
      return {
        ...state,
        ...{
          transactions: {
            ...defaultState.transactions,
            isLoading: true,
          },
        },
      };
    case types.GET_DOMAIN_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ...{
          transactions: {
            ...defaultState.transactions,
            transactions: action.response.txs,
          },
        },
      };
    case types.GET_DOMAIN_TRANSACTIONS_ERROR:
      return {
        ...state,
        ...{
          transactions: {
            ...defaultState.transactions,
            error: action.error,
          },
        },
      };
    case types.GET_DOMAIN_SUBDOMAINS_START:
      return {
        ...state,
        ...{
          subdomains: {
            ...defaultState.subdomains,
            isLoading: true,
          },
        },
      };
    case types.GET_DOMAIN_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        ...{
          subdomains: {
            ...defaultState.subdomains,
            subdomains: action.response.subdomains,
          },
        },
      };
    case types.GET_DOMAIN_SUBDOMAINS_ERROR:
      return {
        ...state,
        ...{
          subdomains: {
            ...defaultState.subdomains,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
};

export default domain;
