import React from 'react';
import PropTypes from 'prop-types';
import styles from '../domain.module.scss';

import Card from '../../../components/card/card';
import Details from './domainInfo/details';

const DomainInfo = ({ info: { isLoading, domainData } }) => (
  <div className={`  container ${styles.header}`}>
    <Card
      header={<Card.Header
        hash={!isLoading && domainData ? domainData.domainName : ''}
        fullHash={!isLoading && domainData ? domainData.domainName : ''}
        title="Domain"
        columns="two"
      />}
      content={<Details isLoading={isLoading} domainData={domainData} />}
    />
  </div>
);

DomainInfo.displayName = 'DomainInfo';
DomainInfo.propTypes = {
  info: PropTypes.shape({
    isLoading: PropTypes.bool,
    domainData: PropTypes.object,
  }),
};

export default DomainInfo;
