import React from 'react';
import PropTypes from 'prop-types';
import styles from '../domain.module.scss';
import Tab from '../../../components/tab/tab';
import TransactionsTable from './transactions/transactionsTable';
import SubdomainsTable from './transactions/subdomainsTable';
import Loader from '../../../components/loader/loader';
import { mapTxTypeV2 } from '../../../utils/formatters';

// @todo - move to selector
const transactionListMapper = (txn = {}) => {
  const translatedTxType = mapTxTypeV2(txn);

  // 'Tx Hash', 'Type', 'Value', 'Fee', 'Used Gas', 'Gas Price', 'From', 'To', 'Height'
  return [
    txn.txHash || '',
    translatedTxType,
    txn.txValue || '',
    txn.totalFee || '',
    txn.gasUsed || '',
    txn.gasPrice || '',
    txn.txFrom || '',
    txn.txTo || '',
    txn.currentHeight || '',
  ];
};

// Move to helper
const getTransactionChainsMap = (txs = []) => {
  const result = {};

  txs.forEach(txn => {
    const { txHash: txHashValue } = txn;

    result[`tx_${txHashValue}`] = {
      txHash: txn.txHash || '',
      chainID: txn.chainID || '',
    };
  });

  return result;
};

// @todo - move to selector
const subdomainListMapper = (subdomain = {}) => [
  subdomain.domainName || '',
  subdomain.uri || '',
  subdomain.activeFlag && subdomain.activeFlag === true ? 'active' : 'disabled',
];

const TAB_TRANSACTIONS = 'Transactions';
const TAB_SUBDOMAINS = 'Subdomains';

const Transactions = ({
  isLoading,
  transactions,
  transactionsError,
  subdomains,
  subdomainsError,
  activeTab,
  updateActiveTab,
}) => {
  const transactionsArr = transactions.map(transactionListMapper);
  const transactionsChainMap = getTransactionChainsMap(transactions);
  const subdomainsArr = subdomains.map(subdomainListMapper);

  return (
    <div className={`container ${styles.table}`}>
      {isLoading ? (
        <Loader message="Loading domain data..." />
      ) : (
        <div>
          <div className={styles.tabs}>
            <Tab
              activeTab={activeTab}
              onTabClick={tab => updateActiveTab(tab)}
              tabs={[TAB_TRANSACTIONS, TAB_SUBDOMAINS]}
              tabsStyles={styles}
              autoAlign
            />
          </div>
          {activeTab === TAB_TRANSACTIONS && (
          <TransactionsTable
            transactions={transactionsArr}
            transactionsChainMap={transactionsChainMap}
            transactionsError={transactionsError}
          />)}

          {activeTab === TAB_SUBDOMAINS && (
          <SubdomainsTable
            subdomains={subdomainsArr}
            subdomainsError={subdomainsError}
          />)}
        </div>
      )}
    </div>
  );
};

Transactions.displayName = 'Transactions';
Transactions.propTypes = {
  isLoading: PropTypes.bool,
  transactions: PropTypes.array,
  transactionsError: PropTypes.any,
  subdomains: PropTypes.array,
  subdomainsError: PropTypes.any,
  activeTab: PropTypes.string,
  updateActiveTab: PropTypes.func,
};

export default Transactions;
