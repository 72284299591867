import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab.module.scss';

const Tab = ({
  activeTab, onTabClick, tabs, autoAlign, tabsStyles = {},
}) => (
  <div className={`${styles.tabs} ${tabsStyles.tabs}`}>
    {tabs.map((tab, index) => (
      <div
        key={index}
        className={`${styles.tab} ${tabsStyles.tab} ${activeTab === tab ? `${styles.active} ${tabsStyles.active}` : ''}`}
        onClick={() => onTabClick(tab)}
        style={autoAlign ? { width: `calc(100% / ${tabs.length})` } : {}}
      >
        {tab}
      </div>
    ))}
  </div>
);

Tab.displayName = 'Tab';
Tab.defaultProps = {
  activeTab: '',
  tabs: [],
  autoAlign: false,
};
Tab.propTypes = {
  activeTab: PropTypes.any,
  onTabClick: PropTypes.func,
  tabs: PropTypes.array,
  autoAlign: PropTypes.bool,
  tabsStyles: PropTypes.object,
};

export default Tab;
