import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Import Components
import SearchBar from '../../state/searchBar/searchBar.container';
import NetworkSwitch from '../../state/networkSwitch/networkSwitch.container';
import BlockchainSwitch from '../../state/blockchainSwitch/blockchainSwitch.container';

// Import Assets
import logo from '../../img/ol_logo.png';

// Import Styles
import './header.scss';
import styles from './header.module.scss';

const Header = ({ push }) => {
  const heroSection = ` hero ${styles.heroSection} `;

  return (
    <Fragment>
      <section className={heroSection}>
        <div className="hero-head">
          <div className="container">
            <nav className="navbar" role="navigation" aria-label="dropdown navigation">
              <div className="navbar-brand">
                <a className="navbar-item is-size-7 has-text-weight-semibold" href="#">
                  <img src={logo} alt="OneLedger" onClick={() => push('/')} />
                </a>
                <a
                  role="button"
                  className="navbar-burger burger"
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarBasicExample"
                >
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </a>
              </div>

              <div
                id="navbarBasicExample"
                className="navbar-menu"
              >
                <div className="navbar-start" style={{ minWidth: '70%' }}>
                  <div className="navbar-item is-size-7 has-text-weight-semibold" style={{ width: '100%' }} href="#">
                    <SearchBar />
                  </div>
                </div>

                <div className="navbar-end" style={{ minWidth: '30%' }}>
                  <div className="navbar-item is-size-7 has-text-weight-semibold" href="#">
                    <NetworkSwitch />
                  </div>
                  <div className="navbar-item is-size-7 has-text-weight-semibold" href="#">
                    <BlockchainSwitch />
                  </div>
                </div>
              </div>

            </nav>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Header.displayName = 'Header';
Header.propTypes = {
  push: PropTypes.func,
};

export default Header;
