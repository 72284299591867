import allTxTypes from './txTypes';

const { txTypesAll: txTypes } = allTxTypes;

const transalatedMessages = {
  // olt
  [txTypes.DEFAULT_MESSAGE]: 'Unknown',
  [txTypes.tx_4000]: 'Receive OLT',
  [txTypes.tx_4001]: 'Send OLT',
  [txTypes.tx_4002]: 'Receive ETH',
  [txTypes.tx_4003]: 'Send ETH',

  [txTypes.tx_0000]: 'Unknown',
  [txTypes.tx_4010]: 'Receive OLT to Domain',
  [txTypes.tx_4011]: 'Send OLT to Domain',
  [txTypes.tx_4012]: 'Receive ETH to Domain',
  [txTypes.tx_4013]: 'Send ETH to Domain',

  [txTypes.tx_4020]: 'Create Domain',
  [txTypes.tx_4021]: 'Update Domain',
  [txTypes.tx_4022]: 'Domain On Sale',
  [txTypes.tx_4023]: 'Domain Off Sale',
  [txTypes.tx_4024]: 'Purchase Domain',
  [txTypes.tx_4025]: 'Sell Domain',
  [txTypes.tx_4026]: 'Sell Domain',
  [txTypes.tx_4027]: 'Delete Subdomain',
  [txTypes.tx_4028]: 'Renew Domain',

  [txTypes.tx_4030]: 'Validator Stake',
  [txTypes.tx_4031]: 'Validator Unstake',
  [txTypes.tx_4040]: 'Withdraw',

  // btc
  [txTypes.tx_4050]: 'BTC_LOCK',
  [txTypes.tx_4051]: 'BTC_ADD_SIGNATURE',
  [txTypes.tx_4052]: 'BTC_BROADCAST_SUCCESS',
  [txTypes.tx_4053]: 'BTC_REPORT_FINALITY_MINT',
  [txTypes.tx_4054]: 'BTC_EXT_MINT',
  [txTypes.tx_4055]: 'BTC_REDEEM',
  [txTypes.tx_4056]: 'BTC_FAILED_BROADCAST_RESET',

  // eth
  [txTypes.tx_eth_receive_from_domain]: 'Receive from Domain',
  [txTypes.tx_eth_send_to_domain]: 'Send to Domain',
  [txTypes.tx_eth_receive_from_address]: 'Receive from Address',
  [txTypes.tx_eth_send_to_address]: 'Send to Address',
  [txTypes.tx_eth_lock]: 'Transfer', // make negative amount
  [txTypes.tx_eth_redeem]: 'Transfer', // positive amount

  [txTypes.tx_4060]: 'Transfer', //  ETH_LOCK - positive
  [txTypes.tx_4061]: 'ETH Report Finality Mint', //  ETH_REPORT_FINALITY_MINT
  [txTypes.tx_4062]: 'Transfer', // ETH_REDEEM - make negative amount
  [txTypes.tx_4063]: 'ERC20_LOCK',
  [txTypes.tx_4064]: 'ERC20_REDEEM',

  [txTypes.tx_4070]: 'Proposal create', // PROPOSAL_CREATE
  [txTypes.tx_4071]: 'Proposal cancel', // PROPOSAL_CANCEL
  [txTypes.tx_4072]: 'Proposal fund', // PROPOSAL_FUND
  [txTypes.tx_4073]: 'Proposal vote', // PROPOSAL_VOTE
  [txTypes.tx_4074]: 'Proposal finalize', // PROPOSAL_FINALIZE
  [txTypes.tx_4075]: 'Expire votes', // EXPIRE_VOTES
  [txTypes.tx_4076]: 'Proposal withdraw funds', // PROPOSAL_WITHDRAW_FUNDS

  // explorer only
  [txTypes.tx_5000]: 'Purchase Domain', // DOMAIN_PURCHASE
  [txTypes.tx_5001]: 'Sell Domain', // DOMAIN_SELL
  [txTypes.tx_5002]: 'Send to Domain', // DOMAIN_SELL

  // delegation
  [txTypes.tx_4080]: 'Rewards Withdraw', // REWARDS_WITHDRAW
  [txTypes.tx_4090]: 'Add network delegation', // ADD_NETWORK_DELEGATION
  [txTypes.tx_4091]: 'Network Undelegate', // NETWORK_UNDELEGATE
  [txTypes.tx_4092]: 'Rewards Withdraw Network Delegate', // REWARDS_WITHDRAW_NETWORK_DELEGATE
  [txTypes.tx_4093]: 'Rewards Reinvest Network Delegate', // REWARDS_REINVEST_NETWORK_DELEGATE
};

export const messages = transalatedMessages;
