import { connect } from 'react-redux';
import { getDomain } from './actions';
import Domain from './domain';

export const mapStateToProps = state => ({
  info: state.domain.info,
});

const mapDispatchToProps = {
  getDomain,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Domain);
