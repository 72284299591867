import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/loader/loader';
import MultipleResults from './components/multipleResults/multipleResults';
import styles from './search.module.scss';

class Search extends Component {
  constructor(props) {
    super(props);

    const {
      getDefaultSearch,
      match: { params },
    } = props;

    getDefaultSearch(params);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { query: prevQuery },
      },
    } = prevProps;
    const {
      getDefaultSearch,

      match: { params },
    } = this.props;

    if (prevQuery !== params.query) {
      getDefaultSearch(params);
    }
  }

  render() {
    const { isDefaultSearchLoading, response, push } = this.props;
    if (isDefaultSearchLoading) return <Loader message="Loading data..." />;

    if (response.length > 0) {
      return <MultipleResults data={response} push={push} />;
    }

    return (
      <section className={`${styles.container}`}>
        <div className="container">
          <p className={styles.title}>
            No results found.
          </p>
        </div>
      </section>
    );
  }
}

Search.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  isDefaultSearchLoading: PropTypes.bool,
  response: PropTypes.array,
  getDefaultSearch: PropTypes.func,
  push: PropTypes.func,
};

export default Search;
