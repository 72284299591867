import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Import Constants and Utils
import { searchFilters } from '../../constants/general';

// Import Components
import Dropdown from '../../components/dropdown/dropdown.container';
import searchIcon from '../../img/search-solid.svg';

// Import Styles
import styles from './searchBar.module.scss';

class SearchBar extends Component {
  handleOnChange = event => {
    const { value } = event.target;
    const { updateSearchResult } = this.props;

    updateSearchResult(value);
  };

  handleFilterSelection = value => {
    const { updateFilter } = this.props;

    updateFilter(value);
  };

  handleSearch = () => {
    const { push, query, filter } = this.props;

    push(`/${filter}/${query}`);
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  render() {
    // Declare Variables
    const { query } = this.props;

    // todo - move those 5 styles to the css
    const columnOneStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0.3rem',
      paddingLeft: '0.6',
      justifyContent: 'center',
    };

    const columnTwoStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0.3rem',
      // paddingLeft: '0',
      alignItems: 'center',
    };

    const filterStyle = {
      flex: 1,
    };

    const btnStyle = {
      width: '30%',
    };

    const columnsStyle = {
      width: '100%',
      margin: 0,
    };

    return (
      <div className={styles['search-bar']}>
        <div className="columns" style={columnsStyle}>
          <div className="column" style={columnOneStyle}>
            {/* Search-Icon */}
            <img src={searchIcon} className={styles.icon} alt="search-icon" />
            {/* Search-Input */}
            <input
              className={styles.input}
              name="search"
              value={query}
              onChange={this.handleOnChange}
              onKeyPress={this.handleKeyPress}
              placeholder="Search for Account / Block / Transaction / Domain"
              type="text"
              spellCheck="false"
            />
          </div>
          <div className="column is-5" style={columnTwoStyle}>
            {/* Search-Dropdown-Filter */}
            <div className={styles.filter} style={filterStyle}>
              <Dropdown
                label="Filter By "
                labelStyles={styles['filter-label']}
                options={searchFilters}
                onSelected={this.handleFilterSelection}
              />
            </div>

            {/* Search-Button */}
            <button type="button" className={styles['search-btn']} style={btnStyle} onClick={this.handleSearch}>
              Go
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  updateSearchResult: PropTypes.func,
  updateFilter: PropTypes.func,
  push: PropTypes.func,
  query: PropTypes.string,
  filter: PropTypes.string,
};

export default SearchBar;
