import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';

const SubdomainsTable = ({ subdomains, subdomainsError }) => {
  if (subdomainsError) {
    return (
      <div>Error retrieving domain subdomains. Details: {subdomainsError.message}</div>
    );
  }

  return (
    <Table
      columns={['Sub Domain', 'Website', 'Status']}
      rows={subdomains}
    />
  );
};

SubdomainsTable.displayName = 'SubdomainsTable';
SubdomainsTable.propTypes = {
  subdomains: PropTypes.array,
  subdomainsError: PropTypes.any,
};

export default SubdomainsTable;
