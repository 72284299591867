import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/button/button';

import oneLedger from '../../../img/logo-full.png';
import styles from './errors.module.scss';

const NotFound = ({ push }) => (
  <div className={styles['not-found']}>
    <img src={oneLedger} alt="OneLedger" />
    <div className={styles.text}>
      <h1>
        The page you are looking for does not exist
        <span>
          <FontAwesomeIcon icon={faSadTear} />
        </span>
      </h1>
    </div>
    <div className={styles.actions}>
      <Button
        label="Please return to home"
        onClick={() => push('/')}
        size="regular"
      />
    </div>
  </div>
);

NotFound.displayName = 'NotFound';
NotFound.propTypes = {
  push: PropTypes.func,
};

export default NotFound;
