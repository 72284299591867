import { OLT_REMOTE_URL } from '../constants/general';

// api types
export const ACCOUNTS_API_TYPE = 'accounts';
export const SWAPS_API_TYPE = 'transactions';
export const TRANSACTIONS_API_TYPE = 'transactions';
export const BLOCKS_API_TYPE = 'blocks';
export const DOMAINS_API_TYPE = 'domains';
export const ONS_API_TYPE = 'ons';
export const KPI_API_TYPE = 'metrics';
export const INFO_API_TYPE = 'info';

// networks
export const CHRONOS_NETWORK = 'chronos';

// Ons SDK RPC url
export const ONS_RPC_ENDPOINT = OLT_REMOTE_URL;

// ONS Store config
export const STORE_CONFIG = {
  platform: 'browser',
  storeLocation: __dirname,
};

export const errCodeDomainNotFound = -100502;
