import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './account.module.scss';

import AccountTransactions from './components/transactions.container';
import AccountInfo from './components/accountInfo.container';

class Account extends Component {
  constructor(props) {
    super(props);
    const { currency, page, pagesize } = this.props;
    props.getAccount({ ...props.match.params, currency, page, pagesize });
  }

  componentDidUpdate(prevProps) {
    const { getAccount, match: { params: currentParams } } = this.props;

    const { match: { params: { accountKey } } } = prevProps;

    const { currency, page, pagesize } = this.props;

    if (currentParams.accountKey !== accountKey) {
      getAccount(currentParams);
    }
    if (prevProps.page !== page) {
      getAccount({ ...currentParams, currency, page, pagesize });
    }
  }

  render() {
    return (
      <section className={` section ${styles.account}`}>
        <AccountInfo />
        <AccountTransactions />
      </section>
    );
  }
}

Account.displayName = 'Account';
Account.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  getAccount: PropTypes.func,
  currency: PropTypes.string,
  page: PropTypes.number,
  pagesize: PropTypes.number,
};

export default Account;
