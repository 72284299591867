import React from 'react';
import PropTypes from 'prop-types';

// Import Assets
import logoSmall from '../../../../../img/logo-small.svg';

// Import styles
import styles from './domainResultCard.module.scss';

const DomainResultCard = ({ style, item, type }) => (
  <div className={styles['block-result-card-container']}>
    <div className={styles['result-card-banner']}>
      <p className={styles.text}>{type}</p>
    </div>
    <div className={styles['result-card']} style={style}>
      <img className={styles.logo} src={logoSmall} alt="logo-small" />

      <div className={`${styles.wrapper} has-text-centered-touch`}>
        <div className={styles['validation-label']}>
          <p className={styles.label}>Domain Name:</p>
          <p className={styles.value}>
            {item.domainName}
          </p>
        </div>
        <div className={styles['validation-content']}>
          <p className={styles.label}>Transactions Count:</p>
          <p className={styles.value}>{`${item.transactionsCount}`}</p>
        </div>
      </div>
    </div>
  </div>
);

DomainResultCard.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
  type: PropTypes.string,
};

export default DomainResultCard;
