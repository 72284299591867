import React from 'react';

import PropTypes from 'prop-types';
import styles from './blockchainSwitch.module.scss';

import { blockchainProducts, defaultBlockchain } from '../../constants/general';

import Dropdown from '../../components/dropdown/dropdown.container';

const BlockchainSwitch = (props) => {
  const { push } = props;

  const handleRedirect = (currentValue, option) => {
    const { url } = option;

    const { pathname } = props;

    if (url !== pathname) {
      push(url);
    }
  };

  return (
    <div className={styles['blockchain-switch']}>
      <Dropdown
        labelHoverColor="secondary"
        itemHoverColor="secondary"
        labelContainerStyles={styles['filter-label-container']}
        labelStyles={styles['filter-label']}
        options={blockchainProducts}
        onSelected={() => {}}
        handleRedirect={handleRedirect}
        defaultOptionValue={defaultBlockchain}
      />
    </div>
  );
};

BlockchainSwitch.propTypes = {
  push: PropTypes.func,
  pathname: PropTypes.string,
};


export default BlockchainSwitch;
