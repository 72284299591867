import TopValidators from './topValidators.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: 'topValidators',
    path: ROUTES.TOP_VALIDATORS,
    isSecure: false,
    component: TopValidators,
  },
];
