import React from 'react';
import PropTypes from 'prop-types';
import { getItem } from '../helper';

const TableRow = ({ row, columns, txHashColumnIndex, chains }) => (
  <tr>
    {row.map((item, td) => {
      const txHashValue = row[txHashColumnIndex];

      return (
        <td data-label={columns[td]} key={td}>
          {getItem(columns[td], item, chains, txHashValue)}
        </td>
      );
    })}
  </tr>
);

TableRow.propTypes = {
  row: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  txHashColumnIndex: PropTypes.number,
  chains: PropTypes.object,
};

export default TableRow;
