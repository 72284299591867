import { connect } from 'react-redux';
import { updateActiveTab, updateActivePage } from '../actions';
import Transactions from './transactions';
// Filter swaps since they are not ready yet

export const mapStateToProps = state => ({
  activeTab: state.account.transactions.activeTab,
  isLoading: state.account.transactions.isLoading,
  totalPage: state.account.transactions.totalPage,
  pagesize: state.account.transactions.pagesize,
  page: state.account.transactions.page,
  currency: state.account.transactions.currency,
  transactions: state.account.transactions.transactions.filter(
    txn => txn.type !== 'swap',
  ),
});

const mapDispatchToProps = {
  updateActiveTab,
  updateActivePage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transactions);
