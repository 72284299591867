import * as types from './actionTypes';
import api from '../../config/api';
import { DOMAINS_API_TYPE, CHRONOS_NETWORK, ONS_API_TYPE } from '../../config/constants';

export const updateActiveTab = tab => ({
  type: types.UPDATE_ACTIVE_TAB,
  tab,
});

const getDomainInfoStart = () => ({
  type: types.GET_DOMAIN_INFO_START,
});

const getDomainInfoSuccess = response => ({
  type: types.GET_DOMAIN_INFO_SUCCESS,
  response,
});

const getDomainInfoError = error => ({
  type: types.GET_DOMAIN_INFO_ERROR,
  error,
});

const getDomainInfo = domainName => async dispatch => {
  const onsApi = api(ONS_API_TYPE, CHRONOS_NETWORK);

  dispatch(getDomainInfoStart());

  try {
    const response = await onsApi.getDomainInfo({ domainName });

    const [domainData] = response;
    const { beneficiaryBalance = {} } = domainData;
    const { OLT: oltBalance = {} } = beneficiaryBalance;

    const { balance = '0 OLT' } = oltBalance;
    return dispatch(getDomainInfoSuccess({ ...domainData, balance }));
  } catch (e) {
    return dispatch(getDomainInfoError(e));
  }
};

const getDomainTransactionsStart = () => ({
  type: types.GET_DOMAIN_TRANSACTIONS_START,
});

const getDomainSubdomainsStart = () => ({
  type: types.GET_DOMAIN_SUBDOMAINS_START,
});

const getDomainTransactionsSuccess = response => ({
  type: types.GET_DOMAIN_TRANSACTIONS_SUCCESS,
  response,
});

const getDomainSubdomainsSuccess = response => ({
  type: types.GET_DOMAIN_SUBDOMAINS_SUCCESS,
  response,
});

const getDomainTransactionsError = error => ({
  type: types.GET_DOMAIN_TRANSACTIONS_ERROR,
  error,
});

const getDomainSubdomainsError = error => ({
  type: types.GET_DOMAIN_SUBDOMAINS_ERROR,
  error,
});

const getDomainTransactions = domainName => async dispatch => {
  dispatch(getDomainTransactionsStart());
  try {
    const response = await api(DOMAINS_API_TYPE, CHRONOS_NETWORK).txsDomainGet(domainName);

    return dispatch(getDomainTransactionsSuccess(response));
  } catch (e) {
    return dispatch(getDomainTransactionsError(e));
  }
};

const getDomainSubdomains = domainName => async dispatch => {
  const onsApi = api(ONS_API_TYPE, CHRONOS_NETWORK);

  dispatch(getDomainSubdomainsStart());

  try {
    const response = await onsApi.getSubDomains({ domainName });

    return dispatch(getDomainSubdomainsSuccess({ subdomains: response }));
  } catch (e) {
    return dispatch(getDomainSubdomainsError(e));
  }
};

export const getDomain = (fallback) => async (dispatch) => {
  const { domainName } = fallback;

  dispatch(getDomainInfo(domainName));
  dispatch(getDomainTransactions(domainName));
  return dispatch(getDomainSubdomains(domainName));
};
