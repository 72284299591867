import TopDelegators from './topDelegators.container';
import { ROUTES } from '../../constants/general';

export default [
  {
    name: 'topDelegators',
    path: ROUTES.TOP_DELEGATORS,
    isSecure: false,
    component: TopDelegators,
  },
];
