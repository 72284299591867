import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableRow from './components/tableRow';
import Pagination from './components/pagination';

import styles from './table.module.scss';

class PaginationTable extends Component {
  constructor(props) {
    super(props);

    const { updateActivePage = () => { }, chains = {}, txHashColumnIndex = 0 } = props;

    this.onPageChanged = this.onPageChanged.bind(this);
    this.updateActivePage = updateActivePage;
    this.chains = chains;
    this.txHashColumnIndex = txHashColumnIndex;
  }

  onPageChanged(data) {
    const { currentPage } = data;

    this.updateActivePage(currentPage - 1);
  }

  render() {
    const { columns, pageLimit = 20, totalPage = 1, page = 1, rows: allRows } = this.props;

    const totalRows = totalPage * pageLimit;
    if (totalRows === 0) return null;

    return (
      <div className={styles['pagination-table']}>
        <div className={styles.container}>
          <table className="table">
            {columns && (
              <thead>
                <tr>
                  {columns.map((column, th) => (
                    <th key={th}>{column}</th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {allRows.map((row, tr) =>
                (
                  <TableRow
                    key={tr}
                    row={row}
                    columns={columns}
                    chains={this.chains}
                    txHashColumnIndex={this.txHashColumnIndex}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <Pagination
            totalRecords={totalRows}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={this.onPageChanged}
            page={page}
          />
        </div>
      </div>
    );
  }
}

PaginationTable.displayName = 'PaginationTable';
PaginationTable.defaultProps = {
  rows: [],
};
PaginationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  pageLimit: PropTypes.number,
  totalPage: PropTypes.number,
  page: PropTypes.number,
  updateActivePage: PropTypes.func,
  chains: PropTypes.object,
  txHashColumnIndex: PropTypes.number,
};

export default PaginationTable;
