export default {
  development: {
    networks: {
      chronos: process.env.NETWORK_DEV_API_URL,
      socket: process.env.NETWORK_DEV_SOCKET_URL,
    },
  },
  production: {
    networks: {
      chronos: process.env.NETWORK_PROD_API_URL,
      socket: process.env.NETWORK_PROD_SOCKET_URL,
    },
  },
};
