import * as types from './actionTypes';
import { INFO_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';
import api from '../../config/api';

const getTotalValidatorsSuccess = response => ({
  type: types.GET_TOP_VALIDATORS_SUCCESS,
  response,
});

const getTotalValidatorsError = error => ({
  type: types.GET_TOP_VALIDATORS_ERROR,
  error,
});

export const getTotalValidators = () => async dispatch => {
  try {
    const response = await api(INFO_API_TYPE, CHRONOS_NETWORK).infoValidatorGet();

    if (response) {
      dispatch(getTotalValidatorsSuccess(response));
    }
  } catch (e) {
    console.log('e', e);
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    dispatch(getTotalValidatorsError(error));
  }
};
