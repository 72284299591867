import React, { Fragment } from 'react';
import { VIEW_TYPE } from '../../../../constants/general';
import BlockResultCard from './blockResultCard/blockResultCard';
import SwapResultCard from './swapResultCard/swapResultCard';
import TransactionResultCard from './transactionResultCard/transactionResultCard';
import AccountResultCard from './accountResultCard/accountResultCard';
import DomainResultCard from './domainResultCard/domainResultCard';

export const getResultCard = type => props => {
  switch (type) {
    case VIEW_TYPE.BLOCK:
      return <BlockResultCard {...props} />;
    case VIEW_TYPE.SWAP:
      return <SwapResultCard {...props} />;
    case VIEW_TYPE.TRANSACTION:
      return <TransactionResultCard {...props} />;
    case VIEW_TYPE.ACCOUNT:
      return <AccountResultCard {...props} />;
    case VIEW_TYPE.DOMAIN:
      return <DomainResultCard {...props} />;
    default:
      return <Fragment />;
  }
};
