import React from 'react';
import { ROUTES } from '../../constants/general';
import { shortenHash } from '../../utils/formatters';
import Link from '../link/link.container';
import styles from './table.module.scss';

const getTxRoute = item => {
  switch (item.type) {
    case 'swap':
      return ROUTES.SWAP;
    case 'send':
    case 'payment':
    default:
      return ROUTES.TRANSACTION;
  }
};

const getToRoute = item => {
  const isDomain = item.split('.ol').length > 1;
  return isDomain ? ROUTES.DOMAIN : ROUTES.ACCOUNT;
};

const getChainIDQueryParam = (chains, txHashValue) => {
  const chain = chains[`tx_${txHashValue}`] || {};

  const { chainID } = chain;

  return chainID ? `?chainID=${chainID}` : '';
};

const getTxLink = (item, chains) => {
  const chainIDParam = getChainIDQueryParam(chains, item);

  return <Link path={`${getTxRoute(item)}/${item}${chainIDParam}`}>{shortenHash(item)}</Link>;
};

const getBlockHeightLink = (item, chains, txHashValue) => {
  const chainIDParam = getChainIDQueryParam(chains, txHashValue);

  return <Link path={`${ROUTES.BLOCK}/${`${item}${chainIDParam}`}`}>{item}</Link>;
};

export const getItem = (columnType, item, chains = {}, txHashValue = '0') => {
  if (item === '') {
    return item;
  }

  switch (columnType) {
    case 'Tx Hash':
    case 'Hash':
      return getTxLink(item, chains);
    case 'From':
    case 'To':
      return <Link path={`${getToRoute(item)}/${item}`}>{shortenHash(item)}</Link>;
    // case 'Block':
      // return <Link path={`${ROUTES.BLOCK}/${item}`}>{shortenHash(item)}</Link>;
    case 'Sub Domain':
      return <Link path={`${ROUTES.DOMAIN}/${item}`}>{item}</Link>;
    // case 'Block':
    case 'Height':
      return getBlockHeightLink(item, chains, txHashValue);
    case 'Website':
      return <a className={styles.externalUrl} href={item}>{item}</a>;
    default:
      return item;
  }
};
