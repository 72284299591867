import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/table';

const TransactionsTable = ({ transactions, transactionsError, transactionsChainMap }) => {
  if (transactionsError) {
    return (
      <div>Error retrieving domain transactions. Details: {transactionsError.message}</div>
    );
  }

  return (
    <Table
      columns={['Tx Hash', 'Type', 'Value', 'Fee', 'Used Gas', 'Gas Price', 'From', 'To', 'Height']}
      rows={transactions}
      chains={transactionsChainMap}
      txHashColumnIndex={0}
    />
  );
};

TransactionsTable.displayName = 'TransactionsTable';
TransactionsTable.propTypes = {
  transactions: PropTypes.array,
  transactionsChainMap: PropTypes.object,
  transactionsError: PropTypes.any,
};

export default TransactionsTable;
