import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './transaction.module.scss';

import Card from '../../components/card/card';
import Content from './components/content';
import { shortenHash } from '../../utils/formatters';

class Transaction extends Component {
  componentDidMount() {
    const { getTransaction, match, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const chainID = searchParams.get('chainID');

    getTransaction({ ...match.params, chainID });
  }

  componentDidUpdate(prevProps) {
    const {
      getTransaction,
      match: { params: currentParams },
      location,
    } = this.props;

    const {
      match: {
        params: { hash },
      },
    } = prevProps;

    if (currentParams.hash !== hash) {
      const searchParams = new URLSearchParams(location.search);
      const chainID = searchParams.get('chainID');

      getTransaction({ ...currentParams, chainID });
    }
  }

  render() {
    const { transaction, isLoading } = this.props;

    return (
      <section className={` section ${styles.transaction}`}>
        <div className="is-hidden-desktop">
          <Card
            className="container "
            header={
              <Card.Header columns="two" title="Tx ID" hash={shortenHash(transaction.hash)} fullHash={transaction.hash} />
            }
            content={<Content transaction={transaction} isLoading={isLoading} />}
          />
        </div>
        <div className="is-hidden-touch">
          <Card
            className="container "
            header={
              <Card.Header columns="two" title="Tx ID" hash={transaction.hash} fullHash={transaction.hash} />
            }
            content={<Content transaction={transaction} isLoading={isLoading} />}
          />
        </div>
      </section>
    );
  }
}

Transaction.displayName = 'Transaction';
Transaction.propTypes = {
  transaction: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  getTransaction: PropTypes.func,
};

export default Transaction;
