import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import styles from '../card.module.scss';

const Header = ({
  columns,
  className,
  title,
  hash,
  fullHash,
}) => {
  const headerClass = classnames({
    [styles.header]: true,
    [styles[`${columns}-column`]]: true,
    className,
  });

  return (
    <div className={headerClass}>
      {columns === 'two' ? (
        <div className={styles.container}>
          <div className={styles.breadcrumbs}>
            <Link to="/" className={styles.previous}>
              <p className="is-size-6-touch">Dashboard</p>
            </Link>
            <span className={`${styles.arrow} is-size-6-touch`}>&gt;</span>
            <p className={`${styles.current} is-size-6-touch`}>{title}</p>
          </div>
          <div className={`${styles.hash} is-size-6-touch`}>
            {hash && (
              <Fragment>
                <p className="is-size-7-touch">{hash}</p>
                <div className={styles.copy}>
                  <CopyToClipboard
                    text={fullHash}
                  >
                    <FontAwesomeIcon icon={faCopy} data-tip="Click to copy!" data-for="copy" />
                  </CopyToClipboard>
                </div>
                <ReactTooltip id="copy" effect="solid" />
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <h1 className={styles.title}>{title}</h1>
      )}
    </div>
  );
};

Header.displayName = 'CardHeader';
Header.propTypes = {
  columns: PropTypes.oneOf(['one', 'two']).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  hash: PropTypes.string,
  fullHash: PropTypes.string,
};

export default Header;
