import { push } from 'connected-react-router';
import * as types from './actionTypes';
import api from '../../config/api';
import { BLOCKS_API_TYPE, TRANSACTIONS_API_TYPE, CHRONOS_NETWORK } from '../../config/constants';
import { STREAM_LIMIT } from '../../constants/general';

const getBlockInfoSuccess = block => ({
  type: types.GET_BLOCK_SUCCESS,
  block,
});
const getBlockInfoError = error => ({
  type: types.GET_BLOCK_ERROR,
  error,
});

const getBlockTransactionsStart = () => ({
  type: types.GET_BLOCK_TRANSACTIONS_START,
});
const getBlockTransactionsSuccess = transactions => ({
  type: types.GET_BLOCK_TRANSACTIONS_SUCCESS,
  transactions,
});
const getBlockTransactionsError = error => ({
  type: types.GET_BLOCK_TRANSACTIONS_ERROR,
  error,
});

const getBlockTransactions = block => async dispatch => {
  dispatch(getBlockTransactionsStart());
  try {
    const { txHashes } = block;
    const transactions = [];
    if (txHashes) {
      await Promise.all(
        txHashes.map(async hash => {
          const response = await api(TRANSACTIONS_API_TYPE, CHRONOS_NETWORK).txsTxIDGet(hash);
          transactions.push(response);
        }),
      );
    }

    return dispatch(getBlockTransactionsSuccess(transactions));
  } catch (e) {
    const error = {
      message: e.message,
      stack: e.stack || {},
    };
    return dispatch(getBlockTransactionsError(error));
  }
};

const getBlockStreamStart = () => ({
  type: types.GET_BLOCK_STREAM_START,
});
const getBlockStreamSuccess = response => ({
  type: types.GET_BLOCK_STREAM_SUCCESS,
  response,
});
const getBlockStreamError = error => ({
  type: types.GET_BLOCK_STREAM_ERROR,
  error,
});

export const getLastChunkOfBlocks = () => ({
  type: types.GET_LAST_CHUNK_OF_BLOCKS,
});

export const getInitialBlockStream = () => async dispatch => {
  dispatch(getBlockStreamStart());
  try {
    const response = await api(BLOCKS_API_TYPE, CHRONOS_NETWORK).blocksGet('', STREAM_LIMIT);
    if (response) {
      // response.blocks.reverse();
      const blockStreamResponse = [...response.blocks];

      const res = dispatch(getBlockStreamSuccess(blockStreamResponse));
      dispatch(getLastChunkOfBlocks());

      return res;
    }
    return dispatch(getBlockStreamError());
  } catch (e) {
    const error = {
      msg: e.message,
      stack: e.stack || {},
      err: JSON.stringify(e),
    };
    return dispatch(getBlockStreamError(error));
  }
};

const getBlockInfo = (blockId, chainID) => async dispatch => {
  try {
    let block;

    if (chainID) {
      block = await api(BLOCKS_API_TYPE, CHRONOS_NETWORK).blocksBlockHeightGet(blockId, chainID);
    } else {
      block = await api(BLOCKS_API_TYPE, CHRONOS_NETWORK).blocksBlockHeightGet(blockId);
    }
    return dispatch(getBlockInfoSuccess(block));
  } catch (e) {
    const error = {
      message: e.message,
      stack: e.stack || {},
    };
    return dispatch(getBlockInfoError(error));
  }
};

// TODO: Remove
export const clearBlockStreamInterval = () => (__, getState) => {
  const {
    block: {
      blockStream: { intervalID },
    },
  } = getState();

  clearInterval(intervalID);
};

export const getBlock = fallback => async (dispatch, getState) => {
  const { blockId, chainID } = fallback;

  await dispatch(getBlockInfo(blockId, chainID));
  const { block } = getState().block;
  return dispatch(getBlockTransactions(block));
};

export const getNextBlock = () => async (dispatch, getState) => {
  const {
    block: {
      header: { height: blockId, chainID },
    },
  } = getState().block;

  const chainIDParam = chainID ? `?chainID=${chainID}` : '';

  return dispatch(push(`/block/${blockId + 1}${chainIDParam}`));
};

export const getPreviousBlock = () => async (dispatch, getState) => {
  const {
    block: {
      header: { height: blockId, chainID },
    },
  } = getState().block;

  const chainIDParam = chainID ? `?chainID=${chainID}` : '';

  return dispatch(push(`/block/${blockId - 1}${chainIDParam}`));
};

// Socket actions
export const receiveBlockHeader = blockHeader => ({
  type: types.NEW_BLOCK_RECEIVE,
  response: blockHeader,
});
