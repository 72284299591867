import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../transaction.module.scss';
import Loader from '../../../components/loader/loader';
import Link from '../../../components/link/link.container';
import { ROUTES } from '../../../constants/general';
import { shortenHash, mapTxType, dateFormatter } from '../../../utils/formatters';

const getTxAmount = (tx = {}) => {
  const { recipients = [] } = tx;

  const [recipient = {}] = recipients;

  return recipient.amount || 0;
};

const getChainIDQueryParam = (tx = {}) => {
  const { chainID } = tx;

  return chainID ? `?chainID=${chainID}` : '';
};

const getToRoute = (item = '') => {
  const isDomain = item.split('.ol').length > 1;
  return isDomain ? ROUTES.DOMAIN : ROUTES.ACCOUNT;
};

// @TODO - to move harcoded padding to the styles table
const Content = ({ transaction, isLoading }) => (
  <div className={`${styles.content} `} style={{ paddingLeft: '0.7rem' }}>
    {isLoading ? (
      <Loader message="Loading transaction data..." />
    ) : (
      <Fragment>
        <div className={styles['three-column']}>
          <div className={styles['column-1']}>
            <div className={styles.label}>Value:</div>
            <div className={styles.value}>{getTxAmount(transaction)}</div>
          </div>
          <div className={styles['column-2']}>
            <div className={styles.label}>Type:</div>
            <div className={styles.value}>{mapTxType(transaction)}</div>
          </div>
          <div className={styles['column-3']}>
            <div className={styles.label}>Block Height:</div>
            <div className={styles.value}>
              <Link path={`${ROUTES.BLOCK}/${transaction.blockHeight}${getChainIDQueryParam(transaction)}`}>
                {transaction.blockHeight}
              </Link>
            </div>
          </div>
        </div>
        {transaction.fee && (
          <div className={styles['three-column']}>
            <div className={styles['column-1']}>
              <div className={styles.label}>Fee:</div>
              <div className={`${styles.value} is-size-6`}>{(transaction.fee.totalFee || 'n/a')}</div>
            </div>
            <div className={`${styles['column-2']} `}>
              <div className={styles.label}>Gas Price:</div>
              <div className={`${styles.value} is-size-6`}>{transaction.fee.amount}</div>
            </div>
            <div className={`${styles['column-3']} `}>
              <div className={styles.label}>Used Gas:</div>
              <div className={`${styles.value} is-size-6 has-text-right`}>{transaction.fee.gas}</div>
            </div>
          </div>
        )}
        <div className={styles['one-column']}>
          <div className={styles.label}>Timestamp:</div>
          <div className={`${styles.value} is-size-6 `}>{transaction && dateFormatter(transaction.blockTimeStamp)}</div>
        </div>
        {transaction.from && (
          <div className={styles['one-column']}>
            <div className={styles.label}>From:</div>
            <div className={styles.value}>
              <div className="is-hidden-desktop">
                <Link path={`${getToRoute(transaction.from)}/${transaction.from}`}>
                  {shortenHash(transaction.from)}
                </Link>
              </div>
              <div className="is-hidden-touch">
                <Link path={`${getToRoute(transaction.from)}/${transaction.from}`}>
                  {transaction.from}
                </Link>
              </div>
            </div>
          </div>
        )}
        {transaction.recipients &&
          transaction.recipients.map(recipient => (
            <div className={styles['two-column']} index={recipient.account} key={recipient.account}>
              <div className={styles['column-1']}>
                <div className={styles.label}>To:</div>
                <div className={styles.value}>
                  <div className="is-hidden-desktop">
                    <Link path={`${getToRoute(recipient.account)}/${recipient.account}`}>
                      {shortenHash(recipient.account)}
                    </Link>
                  </div>
                  <div className="is-hidden-touch">
                    <Link path={`${getToRoute(recipient.account)}/${recipient.account}`}>
                      {recipient.account}
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          ))}
      </Fragment>
    )}
  </div>
);

Content.propTypes = {
  transaction: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Content;
