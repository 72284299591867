import { connect } from 'react-redux';
import { updateActiveTab } from '../actions';
import Transactions from './transactions';

export const mapStateToProps = state => ({
  activeTab: state.domain.transactions.activeTab,
  isLoading: state.domain.transactions.isLoading,
  transactions: state.domain.transactions.transactions,
  transactionsError: state.domain.transactions.error,
  subdomains: state.domain.subdomains.subdomains,
  subdomainsError: state.domain.subdomains.error,
});

const mapDispatchToProps = {
  updateActiveTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Transactions);
