import React from 'react';
import PropTypes from 'prop-types';

import styles from './table.module.scss';
import { getItem } from './helper';

const Table = ({ columns, rows, chains, txHashColumnIndex }) => (
  <div className={`table-container ${styles.container}`}>
    <table className="table">
      {columns && (
        <thead>
          <tr>
            {columns.map((column, th) => (
              <th key={th}>{column}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, tr) => {
          const txHashValue = row[txHashColumnIndex];

          return (
            <tr key={tr}>
              {row.map((item, td) => (
                <td data-label={columns[td]} key={td}>
                  {getItem(columns[td], item, chains, txHashValue)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

Table.displayName = 'Table';
Table.defaultProps = {
  rows: [],
};
Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array,
  chains: PropTypes.object,
  txHashColumnIndex: PropTypes.number,
};

export default Table;
