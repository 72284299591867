import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../components/button/button';

import styles from '../../block.module.scss';

const Header = ({
  block,
  getNextBlock,
  getPreviousBlock,
  toShowNextBlockButton,
  toShowPrevBlockButton,
}) => (
  <div className={`columns ${styles.header}`}>
    <div className={`column ${styles.breadcrumbs}`}>
      <Link to="/" className={styles.previous}>
        Dashboard
      </Link>
      <span className={styles.arrow}>&nbsp;&gt;</span>
      <p className={styles.current}>Block</p>
      <div className={`  ${styles.copy}`}>
        {block && (
          <Fragment>
            <p>&nbsp;{block.height}</p>
            <CopyToClipboard text={block.height}>
              <FontAwesomeIcon icon={faCopy} data-tip="Click to copy!" data-for="copy" />
            </CopyToClipboard>
            <ReactTooltip id="copy" effect="solid" />
          </Fragment>
        )}
      </div>
    </div>

    <div className={`column is-5 ${styles.actions}`}>
      {toShowPrevBlockButton && (
        <Button className="details" label="Previous Block" onClick={getPreviousBlock} />
      )}
      {toShowNextBlockButton && (
        <Button className="details" label="Next Block" onClick={getNextBlock} />
      )}
    </div>
  </div>
);

Header.propTypes = {
  block: PropTypes.object,
  getNextBlock: PropTypes.func,
  getPreviousBlock: PropTypes.func,
  toShowNextBlockButton: PropTypes.bool,
  toShowPrevBlockButton: PropTypes.bool,
};

export default Header;
