import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './topValidators.module.scss';
import Loader from '../../components/loader/loader';
import TopValidatorsTable from './components/topValidatorsTable';
import Tab from '../../components/tab/tab';
import OnsSdkApi from '../../services/onsApi';

const listMapper = (validatorItem = {}) => {
  const { validator: address, balance, votingPower } = validatorItem;

  return [
    address || '',
    OnsSdkApi.convertPriceToGui(balance || '0') || '',
    votingPower || '',
  ];
};


const TopValidators = (props) => {
  const { getTotalValidators, topValidatorsInfo, isLoading, topValidatorsError } = props;

  const topValidatorsArr = topValidatorsInfo.map(listMapper);

  const sectionContainer = `section ${styles.topValidators}`;
  const tableContainer = `container ${styles.tableContainer}`;

  const loadDataHandler = useCallback(() => {
    getTotalValidators();
  }, [getTotalValidators]);

  useEffect(() => {
    loadDataHandler();
  }, [loadDataHandler]);

  return (
    <section className={sectionContainer}>
      <div className={tableContainer}>
        { isLoading ? (
          <div className={styles.loader}>
            <Loader message="Validators data loading..." />
          </div>
        ) : (
          <div>
            <div className={styles.tabs}>
              <Tab
                activeTab="Top Validators List"
                onTabClick={() => {}}
                tabs={['Top Validators List']}
                autoAlign
              />
            </div>
            <TopValidatorsTable
              validators={topValidatorsArr}
              validatorsError={topValidatorsError}
            />
          </div>

        ) }
      </div>
    </section>
  );
};

TopValidators.propTypes = {
  getTotalValidators: PropTypes.func,
  topValidatorsInfo: PropTypes.array,
  isLoading: PropTypes.bool,
  topValidatorsError: PropTypes.any,
};

TopValidators.displayName = 'TopValidators';

export default TopValidators;
