import * as types from './actionTypes';

export const defaultState = {
  info: {
    isLoading: false,
    accountData: {},
    error: null,
  },
  transactions: {
    isLoading: false,
    transactions: [],
    page: 0,
    totalPage: 0,
    pagesize: 20,
    currency: undefined,
    error: null,
    activeTab: 'Transactions',
  },
};

const account = (state = defaultState, action) => {
  switch (action.type) {
    case types.UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        ...{
          transactions: {
            ...state.transactions,
            page: action.page,
          },
        },
      };
    case types.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        ...{
          transactions: {
            ...state.transactions,
            activeTab: action.tab,
          },
        },
      };
    case types.GET_ACCOUNT_INFO_START:
      return {
        ...state,
        ...{
          info: {
            ...state.info,
            isLoading: true,
          },
        },
      };
    case types.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            accountData: action.response,
          },
        },
      };
    case types.GET_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        ...{
          info: {
            ...defaultState.info,
            error: action.error,
          },
        },
      };
    case types.GET_ACCOUNT_TRANSACTIONS_START:
      return {
        ...state,
        ...{
          transactions: {
            ...state.transactions,
            isLoading: true,
          },
        },
      };
    case types.GET_ACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        ...{
          transactions: {
            ...defaultState.transactions,
            transactions: action.response.txs,
            totalPage: action.response.totalPage,
            page: state.transactions.page,
          },
        },
      };
    case types.GET_ACCOUNT_TRANSACTIONS_ERROR:
      return {
        ...state,
        ...{
          transactions: {
            ...defaultState.transactions,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
};

export default account;
