import React from 'react';
import styles from './networkSwitch.module.scss';

// Import Constants
import { networkFilters, defaultNetworkName } from '../../constants/general';

// Import Components
import Dropdown from '../../components/dropdown/dropdown.container';

// Handles whather a window needs to redirect to another exlorer ui instance
const handleRedirect = (currentValue, option) => {
  const { value: newValue, url } = option;

  if (newValue === currentValue) {
    return;
  }

  if (global.window) {
    global.window.location.href = url;
  }
};

const NetworkSwitch = () => (
  <div className={styles['network-switch']}>
    <Dropdown
      labelHoverColor="secondary"
      itemHoverColor="secondary"
      labelContainerStyles={styles['filter-label-container']}
      labelStyles={styles['filter-label']}
      options={networkFilters}
      onSelected={() => { }}
      handleRedirect={handleRedirect}
      defaultOptionValue={defaultNetworkName}
    />
  </div>
);

export default NetworkSwitch;
