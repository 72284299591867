import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TopValidators from './topValidators';
import { getTotalValidators } from './actions';

export const mapStateToProps = state => ({
  topValidatorsInfo: state.topValidators.data,
  isLoading: state.topValidators.isLoading,
  topValidatorsError: state.topValidators.error,
});


const mapDispatchToProps = {
  getTotalValidators,
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopValidators),
);
