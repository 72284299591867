import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../domain.module.scss';

import Loader from '../../../../components/loader/loader';

const Details = ({ domainData:
  { ownerAddress = '', beneficiaryAccount = '', expireHeight = 0, currentHeight = 0, uri = '', balance = '0 OLT', isLoading },
} = {}) => (

  <div className={styles.content}>
    {isLoading ? (
      <div className={styles.loader}>
        <Loader message="Domain data loading..." />
      </div>
    ) : (
      <div className={`${styles.domainInfo} columns`}>
        <div className={`${styles.infoColumn} column`}>
          <div className={`${styles.infoItem} `}>
            <div className={`${styles.itemLabelContainer}`}>
              <p className={`${styles.itemLabel} is-size-6`}>Owner:</p>
            </div>
            <div className={styles.itemValueContainer}><p className="has-text-weight-bold is-size-6 ">{`${ownerAddress}`}</p></div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemLabelContainer}>
              <p className={`${styles.itemLabel} is-size-6`}>Beneficiary:</p>
            </div>
            <div className={styles.itemValueContainer}><p className="has-text-weight-bold is-size-6">{`${beneficiaryAccount}`}</p></div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemLabelContainer}>
              <p className={`${styles.itemLabel} is-size-6`}>Balance:</p>
            </div>
            <div className={styles.itemValueContainer}><p className="has-text-weight-bold is-size-6">{`${balance}`}</p></div>
          </div>
        </div>
        <div className={`${styles.infoColumn} column`}>
          <div className={styles.infoItem}>
            <div className={styles.itemLabelContainer}>
              <p className={`${styles.itemLabel} is-size-6`}>Valid for:</p>
            </div>
            <div><p className="has-text-weight-bold is-size-6">{`${expireHeight - currentHeight > 0 ? expireHeight - currentHeight : 0}`}</p></div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemLabelContainer}>
              <p className={`${styles.itemLabel} is-size-6`}>Website:</p>
            </div>
            <div><a target="_blank" rel="noopener noreferrer" className={`has-text-weight-bold is-size-6 ${styles.externalUrl}`} href={uri}>{uri}</a></div>
          </div>
        </div>
      </div>
    )}
  </div>
);

Details.displayName = 'DomainInfoDetails';
Details.propTypes = {
  domainData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default Details;
