import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './domain.module.scss';

import DomainTransactions from './components/transactions.container';
import DomainInfo from './components/domainInfo.container';
import NotFound from '../static/errors/notFound.container';
import { errCodeDomainNotFound } from '../../config/constants';

class Domain extends Component {
  constructor(props) {
    super(props);
    props.getDomain(props.match.params);
  }

  componentDidUpdate(prevProps) {
    const { getDomain, match: { params: currentParams } } = this.props;
    const { match: { params: { domainName } } } = prevProps;

    if (currentParams.domainName !== domainName) {
      getDomain(currentParams);
    }
  }

  render() {
    const { info: { error: domainError } } = this.props;
    if (domainError && domainError.message === `${errCodeDomainNotFound}`) {
      return <NotFound />;
    } if (domainError) {
      return (
        <div>Error retrieving domain info. Details!: {domainError.message}</div>
      );
    }

    return (
      <section className={` section ${styles.domain}`}>
        <DomainInfo />
        <DomainTransactions />
      </section>
    );
  }
}

Domain.displayName = 'Domain';
Domain.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  getDomain: PropTypes.func,
  info: PropTypes.shape({
    error: PropTypes.any,
  }),
};

export default Domain;
